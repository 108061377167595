import React from 'react';
import CreatePostButtonDesktop from './create-post-button-desktop';
import PageTitle from './page-title';
import PageTabs from './page-tabs';
import styles from './my-posts-frame-desktop.scss';

const MyPostsFrameDesktop = ({ children }) => (
  <div className={styles.root}>
    <div className={styles.header}>
      <PageTitle />
      <CreatePostButtonDesktop />
    </div>
    <PageTabs />
    {children}
  </div>
);

export default MyPostsFrameDesktop;

import React from 'react';
import CreatePostButtonMobile from './create-post-button-mobile';
import PageTabs, { ALIGNMENT } from './page-tabs';
import styles from './my-posts-frame-mobile.scss';

const MyPostsFrameMobile = ({ children }) => (
  <div className={styles.root}>
    <PageTabs alignment={ALIGNMENT.center} />
    <div className={styles.createPostButton}>
      <CreatePostButtonMobile />
    </div>
    <div className={styles.content}>{children}</div>
  </div>
);

export default MyPostsFrameMobile;
